<template>
  <div>
    <div class="edit">
      <div class="header">
        <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/userIndex' }"
          >个人中心</el-breadcrumb-item
        >
        <el-breadcrumb-item>我的签章</el-breadcrumb-item>
      </el-breadcrumb> -->

        <div class="readcrumb center">
          <readcrumb :breadCrumbList="breadcrumbDto"></readcrumb>
        </div>
      </div>
      <div class="edit-main">
        <div class="form">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="190px"
            class="demo-ruleForm"
          >
            <el-row type="flex">
              <el-col :span="10">
                <el-form-item label="机构ID" prop="name">
                  <el-input
                    v-model="ruleForm.name"
                    placeholder="请输入机构ID"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="14">
                <el-form-item label="单位中文名称" prop="name">
                  <el-input
                    v-model="ruleForm.name"
                    placeholder="请输入单位中文名称"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="10">
                <el-form-item label="单位性质">
                  <el-select
                    v-model="ruleForm.region"
                    placeholder="请选择单位性质"
                  >
                    <el-option label="公司" value="公司"></el-option>
                    <el-option label="部门" value="部门"></el-option>
                  </el-select> </el-form-item
              ></el-col>
              <el-col :span="14">
                <el-form-item label="单位类型">
                  <el-select
                    v-model="ruleForm.region"
                    placeholder="请选择单位类型"
                  >
                    <el-option label="企业" value="企业"></el-option>
                    <el-option
                      label="政府事业单位"
                      value="政府事业单位"
                    ></el-option>
                    <el-option label="社会团体" value="社会团体"></el-option>
                  </el-select> </el-form-item
              ></el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="10">
                <el-form-item label="单位代码类型">
                  <el-select
                    v-model="ruleForm.name"
                    placeholder="请选择单位代码类型"
                  >
                    <el-option
                      label="统一社会信用代码"
                      value="统一社会信用代码"
                    ></el-option>
                    <el-option
                      label="组织机构代码"
                      value="组织机构代码"
                    ></el-option>
                  </el-select> </el-form-item
              ></el-col>
              <el-col :span="14">
                <el-form-item label="单位代码" prop="name">
                  <el-input
                    v-model="ruleForm.desc"
                    placeholder="请输入单位代码"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="10">
                <el-form-item label="单位所属行政区划代码" prop="name">
                  <el-input
                    v-model="ruleForm.name"
                    placeholder="请输入单位所属行政区划代码"
                  ></el-input> </el-form-item
              ></el-col>
              <el-col :span="14">
                <el-form-item label="父级机构" prop="name">
                  <el-input
                    v-model="ruleForm.name"
                    placeholder="请输入单位父级机构,无父级机构不用填写"
                  ></el-input> </el-form-item
              ></el-col>
            </el-row>

            <el-form-item style="margin-left:-30px">
              <el-button type="primary" @click="submitForm('ruleForm')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { xingzhi, leixing, daimaleixing } from "@/api/userCenter.js";
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        region: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          { required: true, message: "请选择活动资源", trigger: "change" },
        ],
        desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      },
      breadcrumbDto: [
        { path: "/userIndex", title: "个人中心" },
        { path: "/qianzhang", title: "我的签章" },
      ],
    };
  },
  methods: {
    submitForm(formName) {
      this.$router.push("qianzhang");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
          this.$route.push("qianzhang");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  components: {
    
  },
};
</script>

<style scoped lang="scss">
.edit {
  width: 1195px;
  margin: 0 auto;
  margin-bottom: 18px;
}
.edit-main {
  background-color: #ffffff;
}
::v-deep .el-input {
  width: 300px;
}

::v-deep .el-breadcrumb__item {
  
  padding-top: 10px;
}

::v-deep .el-button {
  margin-left: 250px;
  width: 300px;
  margin-top: 100px;
}


.form {
  padding: 100px 50px 100px;
}
.el-breadcrumb__item{
  font-size:14px;
}
</style>
